import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import SecureLS from "secure-ls"
import { baseUrl } from '/setting'
const ls = new SecureLS({ isCompression: false })

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: "",
        avatar: "",
        username: "",
        id: "",
        buttons: [],
        keepAlive: [],
        menu: [],
        userInfo: null
    },
    getters: {
        baseUrl:()=> baseUrl,
        isAdmin(state) {
            console.log(state, 1)
            return state?.userInfo?.role == 1
        },
        banner_type() {
            return {
                1: '首页轮播图',
                2: '商城轮播图',
                3: '课程轮播图',
                4: '活动轮播图',
                5: '老师轮播图',
                6: '新闻轮播图'
            }
        },
        order_status() {
            return {
                0: '待付款',
                1: '待发货/待自提',
                2: '待收货/已自提',
                3: '待评价',
                4: '已取消',
                5: '售后退款中',
                6: '已完成',
                7: '售后完成',
                8: '部分退款中'
            }
        }
    },
    mutations: {
        login(state, payload) {
            state.token = payload.token;
        },
        logout(state) {
            state.token = ""
            localStorage.removeItem('token')
        },
        setAdminInfo(state, payload) {
            state.avatar = payload.avatar;
            state.username = payload.username;
            state.id = payload.id;
        },
        setButtons(state, payload) {
            state.buttons = payload;
        },
        setKeepAlive(state, alive) {
            state.keepAlive = alive
        },
        setMenu(state, menu) {
            state.menu = menu
        },
        setUserInfo(state, user) {
            state.userInfo = user
        }
    },
    actions: {
        setKeepAlive(ctx, keepalice) {
            ctx.commit('setKeepAlive', keepalice)
        },
        setMenu(state, menu) {
            state.commit('setMenu', menu)
        }
    },
    modules: {
    },
    plugins: [
        createPersistedState({
            key: 'token',
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            }
        })
    ]
})
