import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Index from '../views/index.vue'
import Login from '../views/login.vue'
import { Message } from "element-ui"

Vue.use(VueRouter)
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
	return VueRouterPush.call(this, to).catch((err) => err)
}

const routes = [
    {
        path: '/',
        name: 'view-index',
        component: Index,
        children: [
            {
                path: '/',
                name: 'view-dashboard',
                component: () => import(/* webpackChunkName: 'view-dashboard' */ '../views/dashboard/index.vue')
            },
            {
                path: '/menu',
                name: 'view-menu',
                component: () => import(/* webpackChunkName: 'view-menu' */ '../views/menu/index.vue')
            },
            {
                path: '/admin',
                name: 'view-admin',
                component: () => import(/* webpackChunkName: 'view-admin' */ '../views/admin/index.vue')
            },
            {
                path: '/role',
                name: 'view-role',
                component: () => import(/* webpackChunkName: 'view-role' */ '../views/role/index.vue')
            },
            {
                path: '/member',
                name: 'view-member',
                component: () => import(/* webpackChunkName: 'view-member' */ '../views/member/index.vue')
            },
            {
                path: '/admin-log',
                name: 'view-admin-log',
                component: () => import(/* webpackChunkName: 'view-admin-log' */ '../views/admin-log/index.vue')
            },
            {
                path: '/open-door-records',
                name: 'view-open-door-records',
                component: () => import(/* webpackChunkName: 'view-open-door-records' */ '../views/open-door-records/index.vue')
            },
            {
                path: '/shop',
                name: 'view-shop',
                component: () => import(/* webpackChunkName: 'view-shop' */ '../views/shop/index.vue')
            },
            {
                path: '/article-type',
                name: 'view-article-type',
                component: () => import(/* webpackChunkName: 'view-article-type' */ '../views/article-type/index.vue')
            },
            {
                path: '/article',
                name: 'view-article',
                component: () => import(/* webpackChunkName: 'view-article' */ '../views/article/index.vue')
            },
            {
                path: '/system-config',
                name: 'view-system-config',
                component: () => import(/* webpackChunkName: 'view-system-config' */ '../views/system-config/index.vue')
            },
            {
                path: '/file',
                name: 'view-file',
                component: () => import(/* webpackChunkName: 'view-file' */ '../views/file/index.vue')
            },
            {
                path: '/archive',
                name: 'view-archive',
                component: () => import(/* webpackChunkName: 'view-archive' */ '../views/archive/index.vue')
            },
            {
                path: '/material',
                name: 'view-material',
                component: () => import(/* webpackChunkName: 'view-material' */ '../views/material/index.vue')
            },
            {
                path: '/reservation',
                name: 'view-reservation',
                component: () => import(/* webpackChunkName: 'view-reservation' */ '../views/reservation/index.vue')
            },
            {
                path: '/rfm',
                name: 'view-rfm',
                component: () => import(/* webpackChunkName: 'view-rfm' */ '../views/rfm/index.vue')
            },
            {
                path: '/rfm-setup',
                name: 'view-rfm-setup',
                component: () => import(/* webpackChunkName: 'view-rfm-setup' */ '../views/rfm-setup/index.vue')
            },
            {
                path: '/rfm-detail',
                name: 'view-rfm-detail',
                component: () => import(/* webpackChunkName: 'view-rfm-detail' */ '../views/rfm-detail/index.vue')
            },
            {
                path: '/issue-coupons',
                name: 'view-issue-coupons',
                component: () => import(/* webpackChunkName: 'view-issue-coupons' */ '../views/issue-coupons/index.vue')
            },
            {
                path: '/coupon',
                name: 'view-coupon',
                component: () => import(/* webpackChunkName: 'view-coupon' */ '../views/coupon/index.vue')
            },
            {
                path: '/goods',
                name: 'view-goods',
                component: () => import(/* webpackChunkName: 'view-goods' */ '../views/goods/index.vue')
            },
            {
                path: '/bi',
                name: 'view-bi',
                component: () => import(/* webpackChunkName: 'view-bi' */ '../views/bi/index.vue')
            },
            {
                path: '/goods/add',
                name: 'view-goods-add',
                component: () => import(/* webpackChunkName: 'view-goods-add' */ '../views/goods/add.vue')
            },{
                path: '/goods/edit',
                name: 'view-goods-edit',
                component: () => import(/* webpackChunkName: 'view-goods-add' */ '../views/goods/edit.vue')
            },
            {
                path: '/goods-type',
                name: 'view-goods-type',
                component: () => import(/* webpackChunkName: 'view-goods-type' */ '../views/goods-type/index.vue')
            },
			{
                path: '/order',
                name: 'view-order',
                component: () => import(/* webpackChunkName: 'view-order' */ '../views/order/index.vue')
            },{
                path:'/banner',
                name:'article-banner',
                component:()=>import('../views/banner/index.vue') 
            },{
                path:'/assignment',
                name:'article-assignment',
                component:()=>import('../views/assignment/index.vue') 
            },{
                path:'/pick',
                name:'article-pick',
                component:()=>import('../views/selfpick/index.vue') 
            },{
                path:'/logistics',
                name:'article-logistics',
                component:()=>import('../views/logistics/index.vue') 
            },{
                path:'/aftersales',
                name:'article-aftersales',
                component:()=>import('../views/aftersales/index.vue') 
            },{
                path:'/agent',
                name:'article-agent',
                component:()=>import('../views/agent/index.vue') 
            },{//评价
                path:'/evaluate',
                name:'article-evaluate',
                component:()=>import('../views/evaluate/index.vue') 
            },{//课程
                path:'/course',
                name:'article-course',
                component:()=>import('../views/course/index.vue') 
            },{//老师
                path:'/teacher',
                name:'article-teacher',
                component:()=>import('../views/teacher/index.vue') 
            },{//老师标签
                path:'/teachertype',
                name:'article-teachertype',
                component:()=>import('../views/teacher/teacher-type/index.vue') 
            },{//老师专业
                path:'/teachermajor',
                name:'article-teachermajor',
                component:()=>import('../views/teacher/teacher-major/index.vue') 
            },{//社区列表
                path:'/community',
                name:'article-community',
                component:()=>import('../views/community/index.vue') 
            },{//排课列表
                path:'/coursed',
                name:'article-coursed',
                component:()=>import('../views/course/coursed/index.vue') 
            },{//课程分类列表
                path:'/coursetype',
                name:'article-coursetype',
                component:()=>import('../views/course/coursetype/index.vue') 
            },{//志愿者
                path:'/volunteer',
                name:'article-volunteer',
                component:()=>import('../views/volunteer/index.vue') 
            },{//志愿者标签
                path:'/volunteertype',
                name:'article-volunteertype',
                component:()=>import('../views/volunteer/teacher-type/index.vue') 
            },{//志愿者职业
                path:'/volunteermajor',
                name:'article-volunteermajor',
                component:()=>import('../views/volunteer/teacher-major/index.vue') 
            },{//志愿者职业
                path:'/volunteertime',
                name:'article-volunteertime',
                component:()=>import('../views/volunteer/volunteertime/index.vue') 
            },{//活动
                path:'/activity',
                name:'article-activity',
                component:()=>import('../views/activity/index.vue') 
            },{//活动报名
                path:'/activitysign',
                name:'article-activitysign',
                component:()=>import('../views/activity/activitysign/index.vue') 
            },{//活动核销明细
                path:'/writeoff',
                name:'article-writeoff',
                component:()=>import('../views/activity/writeoff/index.vue') 
            },{//教具教材费
                path:'/coursemoney',
                name:'article-coursemoney',
                component:()=>import('../views/course/coursemoney/index.vue') 
            },
			{//年龄段
			    path:'/age',
			    name:'article-age',
			    component:()=>import('../views/age/index.vue') 
			},
			{//老师评价
			    path:'/tevaluate',
			    name:'article-tevaluate',
			    component:()=>import('../views/evaluate/teacher.vue') 
			},
			{//活动核销列表
			    path:'/actoff',
			    name:'article-actoff',
			    component:()=>import('../views/activity/actoff/index.vue') 
			},
			{//活动核销列表
			    path:'/courseoff',
			    name:'article-courseoff',
			    component:()=>import('../views/course/courseoff/index.vue') 
			},
			{//密码修改
			    path:'/changeuserinfo',
			    name:'article-changeuserinfo',
			    component:()=>import('../views/changeuserinfo/index.vue') 
			},
			{//积分列表
			    path:'/integral',
			    name:'article-integral',
			    component:()=>import('../views/integral/index.vue') 
			},
			{//积分明细列表
				path:'/intergralinfo',
				name:'article-integralinfo',
				component:()=>import('../views/integral/intergralinfo/index.vue') 
			}
        ]
    },
    {
        path: '/login',
        name: 'view-login',
        component: Login
    },
    {
        path: '*',
        name: 'view-404',
        component: () => import(/* webpackChunkName: 'view-404' */ '../views/404.vue')
    }
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    if (to.path === "/rfm-detail") {
        if (store.state.buttons.findIndex(item => item.path === "rfm-detail") >= 0) {
            next();
        } else {
            Message.error("您没有该页面访问权限");
        }
    } else if (to.path === "/rfm-setup") {
        if (store.state.buttons.findIndex(item => item.path === "rfm-setup") >= 0) {
            next();
        } else {
            Message.error("您没有该页面访问权限");
        }
    } else {
        if (to.path !== '/login' && !store.state.token) {
            router.replace("/login");
        } else {
            next();
        }
    }
})

export default router
